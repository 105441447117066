'use client';

import { red60 } from '@carbon/colors';
import { CloseOutline } from '@carbon/icons-react';
import { Link as CarbonLink, Stack, Tile } from '@carbon/react';
import Link from 'next/link';

export default function NotFound() {
  return (
    <Tile>
      <Stack gap={6}>
        <h2>
          <CloseOutline size={24} color={red60} /> Not found!
        </h2>
        <p>Could not find requested resource</p>
        <p>
          <Link href="/">
            <CarbonLink>Return to the homepage</CarbonLink>
          </Link>
        </p>
      </Stack>
    </Tile>
  );
}
